@import '../../../default';

.card-price{

    box-sizing: border-box;
    padding: 20px;

    display: grid;
    grid-template-rows: 50px 1fr 40px;

    height: 100%;

    .price{
        text-align: left;
        
        span:nth-child(1){
            font-size: 1.5em;
            font-weight: 400;
        }

        span:nth-child(2){
            font-size: 2em;
            color: $color-text-white;
            font-weight: 700;
        }

        span:nth-child(3){
            font-size: 1em;
            font-weight: 400;
        }

    }

    .elements{
        justify-content: flex-start;
        text-align: left;

        ul{
            margin: 0;
            padding: 0;

            li{
                list-style: none;
                margin: 5px auto;
                font-size: 1em;

                &::before{
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    margin: 5px 20px 5px 0px;
                    border-radius: 50%;
                    background-color: $color-text-blue;
                    content: '';
                    outline-offset: 4px;
                    outline: 1px solid $color-text-blue;
                }
            }
        }
    }

    .action{
        .assign{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            color: $color-text-white;
            width: 100%;
            font-weight: 700;
            box-sizing: border-box;
            padding: 10px 20px;
            cursor: pointer;
            margin: 0 auto;
            transition: all linear 300ms;
            background-image: $color-gradient-blue-button;
            transform-origin: center center;

            &:hover{
                transform: scale(1.05);
                transition: all linear 600ms;
            }
        }
    }
}