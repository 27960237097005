@import '../../default';

.page-base{
    width: 100vw;
    height: auto;
    min-height: 100%;
    background-color: $color-backgroud;
    color: $color-text-grey;
    display: grid;
    grid-template-rows: 80px  1fr 120px;
    position: relative;
    font-size: 18px;
 
    > header, 
    > section, 
    > footer{
        position: relative;
        z-index: 2;
    }

    > header{
        width: 100%;
        margin: 0px auto 20px;

        .p-menubar{
            background-color: #15181D;
            border: 0;

            .p-menubar-start{
                min-width: 230px;
            }

            .p-menuitem-link, 
            .p-menuitem-text{
                color: $color-text-white !important;

                &:hover{
                    background-color: transparent !important;
                    text-decoration: underline;
                }
            }
        }
        .top{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 120px 1fr;
            row-gap: 10px;
            height: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 5px;

            .logo{
                font-weight: 700;
                color: #FFF;
                font-size: 2em;
                margin-right: 20px;
                min-width: 200px;
            }

            .menu{
                justify-content: flex-end;
                text-align: right;
                display: flex;

                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;

                    li{
                        list-style: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                        

                    }
                    li + li{
                        margin-left: 15px;
                    }
                }
                
            }
        }
    }

    section{
        margin: 20px auto;
        width: 100%;
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 20px auto;

            h1{
                color: $color-text-white;
            }
        }
    }

    > footer{
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 25px;
            font-size: 0.7em;

            .social{
                color: $color-blue;
            }

            div:nth-child(even){
                text-align: right;

                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;

                    li{
                        list-style: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                        

                    }
                    li + li{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.page-base{

    section{
        .content{

            .p-divider-content{
                background-color: $color-backgroud;
                font-size: 2em;
            }

            .dash-resume{
                width: 100%;
                display: grid;
                grid-template-columns: 2fr 1fr 1fr;
                row-gap: 15px;
                column-gap: 15px;

                .graphic{
                    grid-row: 1 / span 2;
                }
            }
        }
    }
}