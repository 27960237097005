@import '../../default';

.page-site{
    width: 100vw;
    height: auto;
    min-height: 100%;
    background-color: $color-backgroud;
    color: $color-text-grey;
    display: grid;
    grid-template-rows: 80px 500px 3fr 120px;
    position: relative;
    font-size: 18px;

    &::after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;
        background-image: $color-gradient-top;
        content: '';
        display: block;
        transform: skewY(-12deg);
        transform-origin: 0;
        z-index: 2;
    }

    &::before{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;
        background-image: linear-gradient(to bottom, transparent 0%, $color-backgroud 95%, $color-backgroud 100%), 
                        url(../../../image/bgtop.png);
        background-position: bottom left, bottom left;
        background-size: cover, cover;
        background-repeat: no-repeat;
        content: '';
        display: block;
        z-index: 1;
        filter: grayscale(0.7);
    }

    > .banner, 
    > header, 
    > section, 
    > footer{
        position: relative;
        z-index: 3;
    }

    header{
        .top{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 120px 1fr;
            row-gap: 10px;
            height: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 0;

            .logo{
                font-weight: 700;
                color: #FFF;
                font-size: 2em;
            }

            .menu{
                justify-content: flex-end;
                text-align: right;
                display: flex;

                .sign-up,
                .sign-in{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    color: $color-text-white;
                    width: 120px;
                    font-weight: 700;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    cursor: pointer;
                    margin: 0 5px;
                    transition: all linear 300ms;

                    &:hover{
                        transform: scale(1.05);
                        transition: all linear 600ms;
                    }
                }

                .sign-up{
                    background-image: $color-gradient-blue-button;
                }

                .sign-in{
                    background-color: $color-grey-dark;
                    margin-left: 15px;
                }
            }
        }
    }

    .banner{
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 0;
        
        .content{
            margin: 0 auto;
            width: 100%;
            max-width: 1000px;

            .title{
                color: $color-text-white;
            }

            > * {
                width: 100%;
            }

            .buttons{
                display: flex;
                width: 100%;
                max-width: 50%;
                box-sizing: border-box;
                padding: 20px 0;
                align-items: center;

                .read-more,
                .contact{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    color: $color-text-white;
                    width: auto;
                    font-weight: 700;
                    box-sizing: border-box;
                    padding: 10px  20px;
                    cursor: pointer;
                    transition: all linear 300ms;

                    &:hover{
                        transform: scale(1.05);
                        transition: all linear 600ms;
                    }
                }

                .read-more{
                    background-image: $color-gradient-blue-button;
                }

                .contact{
                    background-color: $color-grey-dark;
                    margin-left: 15px;
                }
            }
        }
    }

    section{
        .content{
            margin: 10px auto;
            width: 100%;
            max-width: 1000px;

            strong{
                color: $color-text-white;
            }

            h1{
                color: $color-text-white;
                text-align: center;
            }

            .data-info{
                width: 100%;
                
                .data-text{
                    align-items: center;
                    justify-content: center;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 10px;
                    text-align: center;
                    margin: 20px auto;

                    h1{
                        color: $color-text-blue;
                        font-size: 2em;
                        font-weight: 700;
                    }
                }
            }

            .data-receive{
                width: 100%;
                margin-top: 100px;
                
                .data-text{
                    align-items: center;
                    justify-content: center;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 20px;
                    text-align: center;
                    margin: 20px auto;

                    h1{
                        font-size: 1.5em;
                        font-weight: 700;
                    }
                }
            }

            .data-prices{
                width: 100%;
                margin-top: 50px;
                background-image: url(../../../image//abstract.png);
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: center center;

                .text{
                    text-align: center;
                    width: 100%;
                    max-width: 1000px;
                    margin: 0 auto;
                }
                
               > .card-list{
                    justify-content: space-around;
                    display: flex;
                    text-align: center;
                    box-sizing: border-box;
                    padding: 40px 20px;
                    margin: 20px auto;

                   > .card{
                        width: 300px;
                        height: 350px;
                        background-color: $color-grey-dark;
                        border-radius: 3px;
                        box-shadow: 10px 0 20px rgba(0, 0, 0, 0.5);
                    }
                }
            }


            .box-read-more{
                width: 100%;
                background-color: #15181D;
                box-sizing: border-box;
                padding: 40px 40px;
                margin: 20px auto;
                border-radius: 3px;
                position: relative;

                &::before{
                    position: absolute;
                    top: 0;
                    right: 10px;
                    width: 100%;
                    height: 100%;
                    background-image: url(../../../image//abstract.png);
                    background-repeat: no-repeat;
                    background-position: top right;
                    content: '';
                    display: block;
                    filter: grayscale(1);
                }
                
                .content{
                    position: relative;
                    width: 100%;
                    max-width: 1000px;
                    display: grid;
                    grid-template-columns: 1fr 220px;
                    color: $color-text-white;
                    z-index: 2;

                    .text{
                        font-size: 1.5em;
                        display: flex;
                        align-items: center;
                    }

                    .button{
                        justify-content: flex-end;
                        display: flex;
                        .read-more{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                            color: $color-text-white;
                            width: 200px;
                            font-weight: 700;
                            box-sizing: border-box;
                            padding: 10px 20px;
                            cursor: pointer;
                            margin: 0 5px;
                            transition: all linear 300ms;

                            &:hover{
                                transform: scale(1.05);
                                transition: all linear 600ms;
                            }
                        }

                        .read-more{
                            background-image: $color-gradient-blue-button;
                        }
                    }
                }
            }

        }
    }

    footer{
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 25px;
            font-size: 0.7em;

            .social{
                color: $color-blue;
            }

            div:nth-child(even){
                text-align: right;

                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;

                    li{
                        list-style: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                        

                    }
                    li + li{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}