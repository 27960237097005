$color-backgroud: #1D2026;
$color-grey-dark: #242830;
$color-gradient-top: linear-gradient(80deg, rgba(36, 40, 48, 0.5) 0%, rgba(36, 40, 48, 0) 100%);

$color-blue: #097dea;
$color-gradient-blue-button: linear-gradient(65deg, #0270D7 0%, #0F8AFD 100%);

$color-text-grey: #8A94A7;
$color-text-white: #FFF;
$color-text-blue: #0060ff;

.separator{
    width: 90%;
    height: 2px;
    margin: 80px auto 60px;
    background-image: linear-gradient(to right, transparent 0%, transparent 20%,  #fff 40%, #fff 60%, transparent 80%, transparent 100%);
    border-radius: 1px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

