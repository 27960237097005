@import '../../../default';

.page-contact,
.page-readmore,
.page-signature,
.page-login,
.page-signup,
.page-faq{
    width: 100vw;
    height: auto;
    min-height: 100%;
    background-color: $color-backgroud;
    color: $color-text-grey;
    display: grid;
    grid-template-rows: 80px  1fr 120px;
    position: relative;
    font-size: 18px;
 
    > header, 
    > section, 
    > footer{
        position: relative;
        z-index: 2;
    }

    > header{
        .top{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 120px 1fr;
            row-gap: 10px;
            height: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 0;

            .logo{
                font-weight: 700;
                color: #FFF;
                font-size: 2em;
            }

            .menu{
                justify-content: flex-end;
                text-align: right;
                display: flex;

                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;

                    li{
                        list-style: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                        

                    }
                    li + li{
                        margin-left: 15px;
                    }
                }
                
            }
        }
    }

    section{
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 20px auto;

            h1{
                color: $color-text-white;
            }
        }
    }

    > footer{
        .content{
            width: 100%;
            max-width: 1000px;
            margin: 10px auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 25px;
            font-size: 0.7em;

            .social{
                color: $color-blue;
            }

            div:nth-child(even){
                text-align: right;

                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;

                    li{
                        list-style: none;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                        

                    }
                    li + li{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.page-login{
    section{
        display: flex;
        align-items: center;
        justify-content: center;

        .content{
            max-width: 500px;

            h1{
                text-align: center;
            }
        }
    }
}

.page-faq{
    .p-accordion .p-accordion-header .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
    .p-accordion-header{
        background-color: transparent !important;
        box-shadow: unset !important;
        border: 0 !important;
        color: $color-text-white !important;
        outline: 0 !important;
        border-color: transparent !important;

        &:hover{
            background-color: transparent !important;
            color: $color-text-blue !important;
        }

    }

    .p-accordion-tab.p-accordion-tab-active,
    .p-highlight{
        color: $color-text-blue !important;
    }

    .p-accordion .p-accordion-content{
        background-color: $color-grey-dark !important;
        border: 0 !important;
        color: $color-text-white;
        border-radius: 5px;
    }

    .box-read-more{
        width: 100%;
        background-color: #15181D;
        box-sizing: border-box;
        padding: 40px 40px;
        margin: 20px auto;
        border-radius: 3px;
        position: relative;

        &::before{
            position: absolute;
            top: 0;
            right: 10px;
            width: 100%;
            height: 100%;
            background-image: url(../../../../image/abstract.png);
            background-repeat: no-repeat;
            background-position: top right;
            content: '';
            display: block;
            filter: grayscale(1);
        }
        
        .content{
            position: relative;
            width: 100%;
            max-width: 1000px;
            display: grid;
            grid-template-columns: 1fr 220px;
            color: $color-text-white;
            z-index: 2;

            .text{
                font-size: 1.5em;
                display: flex;
                align-items: center;
            }

            .button{
                justify-content: flex-end;
                display: flex;
                .read-more{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    color: $color-text-white;
                    width: 200px;
                    font-weight: 700;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    cursor: pointer;
                    margin: 0 5px;
                    transition: all linear 300ms;

                    &:hover{
                        transform: scale(1.05);
                        transition: all linear 600ms;
                    }
                }

                .read-more{
                    background-image: $color-gradient-blue-button;
                }
            }
        }
    }
}