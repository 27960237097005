@import '../../../default';

.ui-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: blur(5px) contrast(4);

    .loading{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 0px;
        width: 100px;
        align-items: center;
        position: relative;

        > div {
            width: 20px;
            height: 20px;
            background-color: $color-text-white;
            border-radius: 50%;
            animation: loading-animation linear infinite;
            animation-duration: 1000ms;
            animation-delay: var(--delay);
        }
    }

    @keyframes loading-animation {
        0%, 50%, 100%{
            transform: scale(1);
        }

        25%{
            transform: scale(2);
        }

        75%{
            transform: scale(0);
        }
    }
}