@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');


@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

@import 'primeflex/primeflex.css';

@import 'primeflex/src/_variables.scss';
@import 'primeflex/src/_grid.scss';
@import 'primeflex/src/_formlayout.scss';
@import 'primeflex/src/_display.scss';
@import 'primeflex/src/_text.scss';
@import 'primeflex/src/flexbox/_flexbox.scss';
@import 'primeflex/src/_spacing.scss';
@import 'primeflex/src/_elevation.scss';

body{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    position: fixed;

    body{
        margin: 0;
        padding: 0;
    }

    > iframe {
        position: relative !important;
        z-index: 1 !important;
    }
}

#root{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    overflow: auto;

    

}