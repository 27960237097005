@import '../../../../default';

.dashboard-cardbasic{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--background);
    border-radius: 4px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
    row-gap: 5px;

    .title{
        color: var(--title);
        border-bottom: 1px dotted #444;
        box-sizing: border-box;
        padding: 0px 10px 10px;
        font-weight: 800;
    }

    .text-value{
        color: var(--value);
        font-size: 1.5em;
        text-align: right;
        font-weight: 200;
    }
}